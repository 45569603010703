import React, { Component } from 'react';
import './index.scss';
import parse from 'html-react-parser';

export type Props = {
  className?: string;
  color?: 'grey' | 'white' | 'black' | 'blue' | 'slightTransparent';
  type?: 'headline' | 'header' | 'subhead' | 'title' | 'body' | 'caption' | 'overline';
  children?: string;
  align?: 'center';
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
  className: '',
  color: 'grey',
  type: 'body',
};

type State = {};

export default class Text extends Component<Props, State> {
  static defaultProps = defaultProps;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, type, color, align, className } = this.props;

    if (!children) return '';

    return (
      <div className={`text ${type} ${color} ${align ?? ''} ${className}`}>
        {parse(children)}
      </div>
    );
  }
}
